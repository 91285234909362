import { useState } from "react"
import Text from "../../../../../core/utils/Text"
import { Background, DText } from "../../../../../styles/CommonStyles"
import Select from "../../../../common/dropdown/Select"
import Input from "../../../../common/input/Input"
import ActionButton from "../../../../common/buttons/ActionButton"
import { useChangeUserBalance } from "../../../../../core/services/react-query/user"
import { stringToNumber } from "../../../../../core/utils/common"



const ChangeBalance = ({ user, refetch }) => {

    const types = ['deposit', 'withdraw']
    const [type, setType] = useState('')
    const [amount, setAmount] = useState('')


    const { mutate: changeBalance, isLoading } = useChangeUserBalance()

    const onSubmitClicked = () => {
        changeBalance({
            amount: stringToNumber(amount),
            member_id: user?.id,
            type,
            wallet_name: 'USDT'
        })
    }

    return (
        <div className={`mx-auto w-[320px] flex flex-col gap-4 py-5 min-h-[200px]`}>
            <DText primary>
                <Text tid={'change-balance-note'} />
            </DText>

            <div className={'flex flex-col gap-5 mt-3'}>

                <Background bg={'mainBg'}>
                    <Select
                        options={types}
                        value={type}
                        onValueChange={idx => setType(types[idx])}
                        placeHolder={'select-change-type'}
                        width={'320px'}
                        height={'48px'}
                        size={'small'}
                    />
                </Background>

                <Background bg={'mainBg'}>
                    <Input
                        value={amount}
                        label={'amount-usdt'}
                        onInputChange={setAmount}
                        height={'48px'}
                        number
                    />
                </Background>

                <ActionButton
                    active={!!type && !!amount}
                    onClick={onSubmitClicked}
                    loading={isLoading}
                >
                    <Text tid='submit' />
                </ActionButton>
            </div>
        </div>
    )
}

export default ChangeBalance