import { Suspense } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom"
import GlobalLoading from "../components/common/loading/GlobalLoading";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile/Profile";
import Login from "./Login";
import { useSetAxiosHeaders } from "../core/hooks/common/useSettings";
import MainLayout from "../components/layout/main/MainLayout";
import Users from "./users/Users";
import UserDetails from "./users/UserDetails";
import Reports from "./reports";
import Settings from "./setting";
import AddUser from "./users/AddUser";
import UserSubDetails from "./users/UserSubDetails";
import { useMainContext } from "../core/contexts/main";
import Transactions from "./reports/transactions/Transactions";
import Medical from "./medical";
import Doctors from "./medical/doctors/Doctors";
import DoctorDetails from "./medical/doctors/DoctorDetails";
import TimeSlots from "./medical/time_slots/TimeSlots";



const Index = () => {

    // setting headers
    useSetAxiosHeaders()
    const { token } = useMainContext()

    return (
        <Router basename={process.env.PUBLIC_URL}>
            <Suspense fallback={<GlobalLoading />}>
                <MainLayout>
                    {!!token ?
                        <Routes>
                            {routes.map(route => {

                                const { path, Component } = route
                                return <Route key={path} path={path} element={<Component />} />
                            })}
                            <Route path={'*'} element={<Navigate to="/profile" replace />} />
                        </Routes>
                        :
                        <Routes>
                            <Route key={'login'} path={'/login'} element={<Login />} />
                            <Route path={'*'} element={<Navigate to="/login" replace />} />
                        </Routes>
                    }

                </MainLayout>
            </Suspense>
        </Router>
    )
}


export const routes = [
    // root
    { path: '/dashboard', Component: Dashboard },
    { path: '/profile', Component: Profile },

    { path: '/users', Component: Users },
    { path: '/users/:id', Component: UserDetails },
    { path: '/users/:id/:tab', Component: UserDetails },
    { path: '/users/:id/:tab/:detailId', Component: UserSubDetails },
    { path: '/users/add-user', Component: AddUser },

    // medical
    { path: '/medical', Component: Medical },
    { path: '/medical/doctors', Component: Doctors },
    { path: '/medical/doctors/:id', Component: DoctorDetails },

    { path: '/medical/time_slots', Component: TimeSlots },

    // reports
    { path: '/reports', Component: Reports },
    { path: '/reports/transactions', Component: Transactions },

    // setting
    { path: '/setting', Component: Settings },

]


export default Index;
