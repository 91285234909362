import ProfileInfo from "../profile/ProfileInfo";
import { Flex } from "../../../styles/CommonStyles";
import { useParams } from "react-router-dom";
import { useGetAUser } from "../../../core/services/react-query/user";
import { useEffect } from "react";
import UserSetting from "./elements/setting/UserSetting";
import AgentInfo from "../profile/AgentInfo";



const UserSubpages = ({
    activeTab
}) => {

    const params = useParams()
    const { data: user, refetch } = useGetAUser({ user_id: params.id })
    useEffect(() => {
        refetch()
    }, [])

    const subpages = [
        ProfileInfo,
        AgentInfo,
        UserSetting
    ]
    const Subpage = subpages[activeTab]

    return (
        <Flex fw align={'flex-start'} style={{ overflowX: 'auto' }}>
            <Subpage
                detail
                user={user}
                refetch={refetch}
            />
        </Flex>
    )
}


export default UserSubpages
