
export const CacheKeys = {
    users: 'users',
    transactions: 'transactions',
    doctors: 'doctors',
    time_slots: 'time_slots'
}

export const filtersIntitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = {}
    })
    return initial
}


export const paginationInitialState = () => {
    const keys = Object.keys(CacheKeys)
    const initial = {}
    keys.forEach(key => {
        const value = CacheKeys[key]
        initial[value] = { page: 1, per_page: 10 }
    })
    return initial
}

const verifyOptions = ['verified', 'not-verified']
const statusOptions = ['success', 'error', 'pending']
const actionOptions = ['maker', 'taker', 'buy', 'sell']
const causeOptions = ['deposit', 'withdraw', 'order', 'otc', 'admin']
const tokenStatusOptions = ['is-active', 'not-active']
const multiTypeOptions = ['INCREASE', 'DECREASE']
const multiStatusOptions = ['pending', 'done', 'rejected']
const fileTypeOptions = ['user', 'banking', 'coining', 'trade', 'order']
const traceTypeOptions = ['INCREASE', 'DECREASE', 'BLOCKED']
const cumulativeStatusOptions = ['init', 'pending', 'success', 'error']


export const userFilterOptions = [
    { name: '_id', search: '_id' },
    { name: 'name', search: 'name' },
    { name: 'date', search: 'date' },
    { name: 'idNo', search: 'idNo' },
    { name: 'mobile', search: 'mobile' },
    { name: 'email', search: 'email' },
    { name: 'verifyAt', search: 'verifyAt', options: verifyOptions },
]

export const transactionOptions = [
    { name: '_id', search: 'id' },
]

export const doctorsOptions = [
    { name: '_id', search: 'id' },
]

export const timeSlotOptions = [
    { name: '_id', search: 'id' },
]

